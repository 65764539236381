import React, { useEffect } from "react";

// import Custom Components
import Breadcrumb from "../components/common/breadcrumb";
import MainLayout from "../layouts/MainLayout";
import PageHeader from "../components/common/page-header";
import SEO from "../components/common/SEO";
import IconBox from "../components/features/icon-box";
import { Link } from "gatsby";
function AboutOne() {
  useEffect(() => {
    document.querySelector(".footer-middle") &&
      document.querySelector(".footer-middle").classList.add("border-0");
  }, []);

  return (
    <MainLayout>
      <div className="main">
        <SEO title="Jimenez Store Mr. Discount - WholeSale Page" />
        <h1 className="d-none">Jimenez Store Mr. Discount -WholeSale</h1>

        <PageHeader title="Wholesale" />

        {/* <h1 className="d-none">Molla React Ecommerce - </h1> */}

        <Breadcrumb
          title="WholeSale"
          //   parent1={[ "/wholesale"]}
          adClass="border-0 mb-0"
        />

        <div className="container">
          <div
            className="page-header page-header-big text-center"
            style={{
              backgroundImage: `url('/assets/images/about-header-bg.jpg')`,
            }}
          ></div>
        </div>

        <div className="page-content pb-0">
          {/* <div className="container">
            <div className="row">
              <div className="col-lg-6 mb-3 mb-lg-0">
                <h2 className="title">Our Vision</h2>
                <p>
                  We have partnered with Mohawk, the largest flooring company in
                  the USA, to offer the widest possible selection at reasonable,
                  discounted prices. With this deal in place, we can offer the
                  broadest selection of inventory to homeowners all over the
                  nation, with extremely low cost but incredibly high quality.
                  Our goal is to make home remodeling affordable for everyone by
                  bringing you the largest selection of home improvement goods
                  at insanely discounted prices!{" "}
                </p>
              </div>

              <div className="col-lg-6">
                <h2 className="title">Our Mission</h2>
                <p>
                  It doesn’t matter if you’re a homeowner, house flipper, or
                  reseller, you’ll be astonished to see the number of products
                  we have in stock and the record low prices attached to them.
                  Offering 30%-80% off prices compared to other average
                  retailers, as well as our impressive stock of appliances,
                  tools, flooring, outdoor furniture, and everything else in
                  between! Not to mention, our inventory is expanding daily!
                  Remodeling your home has never been easier or more affordable.
                  What are you waiting for? Come visit Jimenez today, and snag
                  some hot deals!{" "}
                </p>
              </div>
            </div>

            <div className="mb-5"></div>
          </div> */}

          <div className="bg-light-2 pt-6 pb-5 mb-3 mb-lg-4">
            <div className="container">
              <div className="row">
                <div className="col-lg-5 mb-3 mb-lg-0">
                  <h2 className="title">
                    Truckloads of Liquidation Merchandise with Industry Low
                    Pricing!
                  </h2>
                  <p className="lead  mb-3">
                    We have a huge selection of Truckload Liquidation
                    opportunities available from some of the industry's top
                    American Department Stores!{" "}
                  </p>
                  <h3 className="title">What We’re About:</h3>
                  <p className="mb-2">
                    Supply and service have been a cornerstone of our company
                    since our inception over a decade ago! We proudly offer
                    high-quality truckloads loads, excellent customer service,
                    and as always, we remain dedicated to offering them at
                    industry-low prices. No middle man! Keeping money in your
                    wallet!
                  </p>
                </div>

                <div className="col-lg-6 offset-lg-1">
                  <div className="about-images">
                    <img
                      src={`/assets/images/about/wholesale-1.jpg`}
                      alt=""
                      className="about-img-back"
                    />
                    {/* <img
                      src={`/assets/images/about/img-2.jpg`}
                      alt=""
                      className="about-img-back"
                    /> */}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="container">
            <div className="row  justify-content-center">
              <h2 className="title mb-3">
                {" "}
                Why Do Customers Keep Coming Back?
              </h2>
            </div>
            <div className="row  justify-content-center">
              <div className="col-lg-3 col-sm-6 icon-box-aboutus box">
                <IconBox
                  boxStyle="icon-box-lg text-center"
                  iconClass="icon-puzzle-piece"
                  title="HUGE SELECTION"
                  text="Daily changing inventory, and a huge selection of truckloads from major retailers. "
                />
              </div>
              <div className="col-lg-3 col-sm-6 icon-box-aboutus box ">
                <IconBox
                  boxStyle="icon-box-lg text-center"
                  iconClass="icon-life-ring"
                  title="MASSIVE PROFIT POTENTIAL"
                  text="With us, you can BUY LOW and SELL HIGH to maximize profits!"
                />
              </div>
              <div className="col-lg-3 col-sm-6 icon-box-aboutus box">
                <IconBox
                  boxStyle="icon-box-lg text-center"
                  iconClass="icon-heart-o"
                  title="TOP OF THE LINE HIGH QUALITY"
                  text="We can work with you to customize and build your truckload to meet your business specific needs."
                />
              </div>
              <div className="col-lg-3 col-sm-6 icon-box-aboutus box">
                <IconBox
                  boxStyle="icon-box-lg text-center"
                  iconClass="icon-heart-o"
                  title="FAST DELIVERY"
                  text="We LITERALLY have truckloads available and we can arrange your shipping as soon as we receive the payment. Our shipping quotes are comparatively lower."
                />
              </div>
            </div>
          </div>

          <div className="bg-light-2 pt-6 pb-5 mb-6 mb-lg-8">
            <div className="container">
              <div className="row">
                <div className="col-lg-5 mb-3 mb-lg-0">
                  <h2 className="title">Who Can Become a Customer?</h2>
                  <p className="lead  mb-3">
                    Auction houses, auctioneers, bargain dealers, dollar stores,
                    drop shippers, eBay shops, flea market vendors, mom and pop
                    stores, non-profits, pawnshops, retailers, truckload sale
                    stores, wholesalers, and workshops. These are just a few of
                    the customers that we do business with, but If you have a
                    business, you can buy from us!
                  </p>

                  <p className="mb-2">
                    <b>
                      If you’re interested in becoming a customer, please feel
                      free to reach out and contact us! We’ll gladly get your
                      account up and running as well as offer some FREE advice
                      to get your business going!
                    </b>
                  </p>

                  <div
                    className="bg-dark text-white"
                    style={{ textAlign: "center" }}
                  >
                    {" "}
                    <span>
                      CALL US TODAY :{" "}
                      <a href="tel:317-478-4402">317-478-4402</a>
                      <br /> EMAIL:{" "}
                      <a href="mailto:jose@jimenezmrdiscount.com">
                        jose@jimenezmrdiscount.com
                      </a>
                    </span>
                  </div>
                </div>

                <div className="col-lg-6 offset-lg-1">
                  <div className="about-images">
                    <img
                      src={`/assets/images/about/wholesale-2.jpg`}
                      alt=""
                      className="about-img-back"
                    />
                    {/* <img
                      src={`/assets/images/about/wholesale-1.jpg`}
                      alt=""
                      className="about-img-back"
                    /> */}
                  </div>
                </div>
              </div>
            </div>
            <div className="container">
              <div
                className="row p-3 mt-2"
                style={{ backgroundColor: "#17254a" }}
              >
                <div className="col-12">
                  <h3 className="text-white text-center">
                    If you are seriously considering to establish a successful
                    business,{" "}
                  </h3>
                  <h3 className="text-white text-center">
                    <span style={{ color: "orange" }}>JIMENEZ</span> is the
                    clear partner.
                  </h3>
                </div>
                <div className="text-white text-center" style={{margin:"0 auto"}}>
                  <p>Click on the button below to see our daily changing inventory. </p>
                  <Link
                    to="#"
                    className="btn btn-outline-primary-2 btn-order btn-block mt-1 mb-1"
                  >
                   Wholesale Direct
                  </Link>
                  <p>Contact us by phone or email if you have any questions. </p>
                </div>
              </div>
            </div>
          </div>

          <div className="mb-2"></div>

          {/* <div className="about-testimonials bg-light-2 pt-6 pb-6">
            <div className="container">
              <h2 className="title text-center mb-3">
                What Customer Say About Us
              </h2>
            </div>
          </div> */}
        </div>
      </div>
    </MainLayout>
  );
}

export default React.memo(AboutOne);
